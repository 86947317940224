import { type ClassValue, clsx } from "clsx";
import day from "dayjs";
import "dayjs/locale/ja";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import haversine from "haversine-distance";
import { init as i18nextInit } from "i18next";
import { twMerge } from "tailwind-merge";
import { z as zod } from "zod";
// @ts-expect-error - no types
import jpPrefecture from "jp-prefecture";
import { customAlphabet } from "nanoid";
// refs. https://github.com/aiji42/zod-i18n/issues/204
// @ts-expect-error - see above
import { zodI18nMap } from "zod-i18n-map/dist/index.mjs";
import zodLocaleJa from "zod-i18n-map/locales/ja/zod.json";
import type { prefectureType } from "~/models/schemas/addresses";

// i18n
i18nextInit({
  lng: "ja",
  resources: {
    ja: { zod: zodLocaleJa },
  },
});

// zod i18n
zod.setErrorMap(zodI18nMap);

// Day.js
day.locale("ja");
day.extend(relativeTime);
day.extend(localizedFormat);
export const dayjs = day;

// Tailwind CSS
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// string utils
export function lowercaseFirstLetter(str: string): string {
  if (!str) return str;
  return str.charAt(0).toLowerCase() + str.slice(1);
}

export function isNumeric(str: string): boolean {
  return /^\d+$/.test(str);
}

// Cloudimage URL Builder
export function imgWithParams(
  url: string,
  options: Record<string, string | number>,
) {
  const params = new URLSearchParams({
    org_if_sml: "1",
    dpr: "2",
    q: "70",
    ...options,
  });
  return `${url}?${params.toString()}`;
}

export const parsePostalCode = (postalCode: string) => {
  return postalCode.replace(/(\d{3})(\d{4})/, "$1-$2");
};

export const addressByPostalCode = async (postalCode: string) => {
  const formatPostalCode = postalCode.replace("-", "");

  if (!/[0-9]{7}/.test(formatPostalCode)) {
    throw new Error("郵便番号を正しく入力してください");
  }

  try {
    const { addresses } = await fetch(
      `https://jp-postal-code-api.ttskch.com/api/v1/${formatPostalCode}.json`,
    ).then((res) => res.json());
    const data = addresses.map((address: { ja: unknown }) => address.ja);

    return data as {
      address1: string;
      address2: string;
      address3: string;
      address4: string;
      prefecture: prefectureType;
    }[];
  } catch (err) {
    throw new Error("住所が見つかりませんでした");
  }
};

export type latitudeLongitude = {
  latitude: number;
  longitude: number;
};

export const getDistanceInKm = (
  pointA: latitudeLongitude,
  pointB: latitudeLongitude,
) => {
  if (
    !pointA.latitude ||
    !pointA.longitude ||
    !pointB.latitude ||
    !pointB.longitude
  ) {
    // Invalid Point
    return null;
  }

  if (
    pointA.latitude == pointB.latitude &&
    pointA.longitude == pointB.longitude
  ) {
    return 0;
  }

  return haversine(pointA, pointB) / 1000; // meters to km
};

export const getAllPrefectureValueAndLabel = () => {
  const allPrefs = jpPrefecture.getAllPref() as {
    id: number;
    region: number;
    name: string;
    short: string;
    kana: string;
    en: string;
    neighbor: number[];
  }[];

  const valAndLabels = allPrefs.map(({ id, name }) => {
    return {
      value: id - 1,
      label: name,
    };
  });

  // Add overseas, and other
  // (https://github.com/asmama-jp/my-commu-app/blob/main/app/models/community_profile.rb#L14-L15)
  valAndLabels.push({ value: valAndLabels.length, label: "海外" });
  valAndLabels.push({ value: valAndLabels.length, label: "その他" });

  return valAndLabels;
};

export const nanoid = (length: number) =>
  customAlphabet("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ", length)();

export const getAllProfessionText = () => {
  return [
    { value: "manager", label: "経営者・役員" },
    { value: "staff", label: "会社員" },
    { value: "contract_employee", label: "契約社員・派遣社員" },
    { value: "part_time", label: "パート・アルバイト" },
    { value: "civil_servant", label: "公務員" },
    { value: "certified", label: "士業" },
    { value: "freelancer", label: "自営業・フリーランス" },
    { value: "homemaker", label: "専業主婦・主夫" },
    { value: "student", label: "学生" },
    { value: "jobless", label: "無職" },
    { value: "others", label: "その他" },
  ];
};

export const isConcierge = (roles?: string[]) =>
  roles ? roles.includes("supporter") : false;

const EWEL_COUPON_PRICE = 600;
const BENEFIT_ONE_COUPON_PRICE = 300;
const BENEFIT_ONE_COUPON_PRICE_FOR_GOLD_PLAN = 550;
export type counponType = "ewel" | "benefit_one";

export const validateCoupon = (couponType: counponType, couponCode: string) => {
  if (couponType === "ewel") {
    const regex = /CB77\d{2}-\d{6}/;
    if (!couponCode.match(regex)) {
      throw new Error("Invalid coupon code");
    }

    return {
      price: EWEL_COUPON_PRICE,
    };
  }

  if (couponType === "benefit_one") {
    const regex = /(\d{12}|\d{15})/;
    if (!couponCode.match(regex)) {
      throw new Error("Invalid coupon code");
    }

    if (couponCode.startsWith("113444")) {
      return {
        price: BENEFIT_ONE_COUPON_PRICE_FOR_GOLD_PLAN,
      };
    }
    return {
      price: BENEFIT_ONE_COUPON_PRICE,
    };
  }

  throw new Error("Invalid coupon type");
};

export const severityLogger = (
  obj: Record<string, unknown>,
  severity: "ERROR" | "WARN" = "ERROR",
) => {
  const logger = severity === "ERROR" ? console.error : console.warn;
  logger(
    JSON.stringify({
      severity,
      ...obj,
    }),
  );
};
